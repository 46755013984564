import React, { useEffect } from 'react';
import Link from 'next/link';
import { useFormContext } from 'react-hook-form';

import { InputSwitch } from 'components/InputSwitch';
import { Icon } from 'components/tokens/Icon';
import { informationCircle } from 'icons/default';
import BlippGaranti from 'public/assets/logotype/blipp-garanti-dark.svg';
import { Modal } from 'components/Modal';
import { Colors } from 'enums/Colors';
import { getFormattedPrice } from 'utils/getFormattedPrice';

import styles from './ExtraControls.module.scss';

export enum Controls {
  Insurance = 'insurance',
  BlippInsurance = 'blippinsurance',
  Delivery = 'delivery',
}

interface IProps {
  isInverted?: boolean;
  hasHomeDeliveryOption: boolean;
}

export const ExtraControls: React.FC<IProps> = ({ isInverted = false, hasHomeDeliveryOption }) => {
  const iconColor = isInverted ? Colors.White : undefined;
  const { watch, setValue } = useFormContext();

  const isWarrantyIncludedByCouponCode = watch('campaign-code-data.warrantyIncluded.active');

  useEffect(() => {
    if (!isWarrantyIncludedByCouponCode) return;

    setValue(Controls.BlippInsurance, true);
  }, [isWarrantyIncludedByCouponCode, setValue]);

  return (
    <div className={styles['extra-controls']}>
      <div className={styles.control}>
        <InputSwitch
          disabled={isWarrantyIncludedByCouponCode || false}
          name={Controls.BlippInsurance}
        />
        <div className={styles.label}>1 års Blipp-garanti</div>
        <div className={styles.details}>
          <Modal ButtonContent={<Icon color={iconColor} icon={informationCircle} />}>
            <h4>Blipp-garantin</h4>

            <p>
              När du köper en bil med Blipp erbjuder vi dig 1 års garanti. Ett tillägg som kan lugna
              både sinnet och plånboken!
            </p>

            <p>
              Garantin täcker skador upp till {getFormattedPrice(85000)} ink moms som kan uppkomma
              på bilen utan din påverkan. Det gäller stora som små funktioner i bilen. Allt från
              motor och växellåda, till elektriska komponenter och multimedia.
            </p>

            <p>
              Klicka{' '}
              <Link
                href="/assets/download/Produktinformation-XtraGaranti-Platinum-2025.pdf"
                passHref
              >
                <a className={styles.link} target="_blank">
                  här
                </a>
              </Link>{' '}
              för att se komplett lista på allt som garantin täcker. Garantin kostar{' '}
              {getFormattedPrice(3995)} och kan bakas in i lånet om du önskar.
            </p>

            <BlippGaranti className={styles['blipp-insurance-logo']} />
          </Modal>
        </div>
      </div>

      {hasHomeDeliveryOption && (
        <div className={styles.control}>
          <InputSwitch name={Controls.Delivery} />
          <div className={styles.label}>Hemleverans fr. {getFormattedPrice(5995)}</div>
          <div className={styles.details}>
            <Modal ButtonContent={<Icon color={iconColor} icon={informationCircle} />}>
              <h4>Hemleverans</h4>

              <p>
                När du köper en bil med blipp erbjuder vi dig hemleverans. Ganska smidigt och
                tidsbesparande? Ellerhur!
              </p>

              <p>
                Vi skickar en rådgivare från Blipp som hämtar upp bilen hos säljaren. Väl på plats
                genomför vi en gemensam visning av bilen digitalt så att du får en bra uppfattning
                om bilens skick och funktioner. Därefter levereras bilen hem till din dörr!
              </p>

              <p>
                <strong>Kostnad för leverans:</strong>
                <br />
                Startavgift {getFormattedPrice(1000)} och {getFormattedPrice(100)} / mil.
              </p>
            </Modal>
          </div>
        </div>
      )}
    </div>
  );
};
